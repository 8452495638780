import request from '@/utils/request'


// 查询视频-标签（新增）列表
export function listTypeLabel(query) {
  return request({
    url: '/platform/video-type-label/list',
    method: 'get',
    params: query
  })
}

// 查询视频-标签（新增）分页
export function pageTypeLabel(query) {
  return request({
    url: '/platform/video-type-label/page',
    method: 'get',
    params: query
  })
}

// 查询视频-标签（新增）详细
export function getTypeLabel(data) {
  return request({
    url: '/platform/video-type-label/detail',
    method: 'get',
    params: data
  })
}

// 新增视频-标签（新增）
export function addTypeLabel(data) {
  return request({
    url: '/platform/video-type-label/add',
    method: 'post',
    data: data
  })
}

// 修改视频-标签（新增）
export function updateTypeLabel(data) {
  return request({
    url: '/platform/video-type-label/edit',
    method: 'post',
    data: data
  })
}

// 删除视频-标签（新增）
export function delTypeLabel(data) {
  return request({
    url: '/platform/video-type-label/delete',
    method: 'post',
    data: data
  })
}
