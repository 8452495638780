<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="标签類型" prop="videoTypeId" >
        <a-select placeholder="请选择标签類型" v-model="form.videoTypeId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(item, index) in tagTypelList" :key='item.id' :value='item.id'>
                        {{ item.name }}
                  </a-select-option>
                </a-select>
      </a-form-model-item>
      <a-form-model-item label="名稱" prop="name" >
        <a-input v-model="form.name" placeholder="请输入名稱" />
      </a-form-model-item>
      <a-form-model-item label="是否推薦" prop="isRecommend" >
        <a-switch v-model="form.isRecommend" checked-children="是" un-checked-children="否"/>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input v-model="form.sort" placeholder="请输入排序" />
      </a-form-model-item>
      <a-form-model-item label="備註" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTypeLabel, addTypeLabel, updateTypeLabel } from '@/api/platform/typeLabel'
import {listTagtype } from '@/api/platform/tagtype'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      tagTypelList: [],
      // 表单参数
      form: {
        id: null,

        videoTypeId: null,

        name: null,

        sort: null,

        createTime: null,

        remark: null,

        isRecommend: null,

        status: 0

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        videoTypeId: [
          { required: true, message: '请选择标签類型', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入标签名稱', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '0.正常，1.下架不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getTypeList();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        videoTypeId: null,
        name: null,
        sort: null,
        createTime: null,
        remark: null,
        isRecommend: null,
        status: 0
      }
    },
    getTypeList () {
      listTagtype(this.queryParam).then(response => {
        this.tagTypelList = response.data
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTypeLabel({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const customValue = this.form.isRecommend ? 1 : 0
          this.form.isRecommend = customValue
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTypeLabel(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTypeLabel(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
